// import { withinViewport /* or withinViewportAsync */ } from 'withinviewport'
const Masonry = require('masonry-layout');
import LazyLoad from "vanilla-lazyload";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import mapboxgl from '!mapbox-gl';

addEventListener("DOMContentLoaded", (event) => {
  if (document.querySelector('#map')) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZmFiZmlzY2hlciIsImEiOiJjbG5lZjl1NnMwZjJnMnhybHNmbXZjNnFlIn0.QcNz8wOxbfJ5fAf_Aidvsg';
    if (window.LNG && window.LAT) {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/light-v11',
        center: [window.LNG, window.LAT], // starting position [lng, lat]
        zoom: 10 // starting zoom
      });
      const marker1 = new mapboxgl.Marker({color: 'black'})
        .setLngLat([window.LNG, window.LAT])
        .addTo(map);
    }
  }

  if (document.querySelector('#gallery')) {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#gallery',
      children: 'a',
      bgOpacity: 0.75,
      showHideAnimationType: 'zoom',
      pswpModule: () => import('photoswipe'),
      padding: {top: 20, bottom: 40, left: 100, right: 100}
    });
    lightbox.init();
  }


  if (document.querySelector('.article__post')) {
    const grid = document.querySelector('.main-container--home');
    const msnry = new Masonry(grid, {
      itemSelector: '.article__post',
      percentPosition: true
    });

    const reLayout = (timeout) => {
      let timer = timeout || 25;
      window, setTimeout(() => {
        msnry.layout();
      }, timer);
    }

    const aLazyLoad = new LazyLoad(
      {
        callback_finish: () => {
          reLayout();

        },
        callback_loaded: (el) => {
          if (el.parentNode && el.parentNode.parentNode && el.parentNode.parentNode.classList.contains('article__post')) {
            el.parentNode.parentNode.classList.add('show')
          }
          reLayout();
        },
        callback_enter: () => {
        }
      },
    );
  }
});

